
/* 상단 롤링 배너 */
.body_wrapper > .header {
  margin-bottom: 0px;
}
.assitance {
  overflow: hidden;
  margin: 0 0 40px;
  .rollingBanner {
    float: left;
    border-bottom: 1px solid #bbb;
    border-left: 1px solid #bbb;
    ul {
      float: left;
      height: 240px;
      overflow: hidden;
      li:nth-child(5), li:nth-child(6) {
        border-bottom: 0px;
      }
      li {
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid #bbb;
        a {
          display: block;
          img {
            height: 100%;
          }
        }
        img {
          vertical-align: top;
        }
        &:first-child {
          border-top: 0;
          + li {
            border-top: 0;
          }
        }
        p.onArr {
          position: absolute;
          right: -12px;
          z-index: 1000;
          display: none;
          top: 30px;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    .visual {
      float: left;
      width: 526px;
      height: 240px;
      p {
        display: none;
        &:first-child {
          display: block;
        }
        &.timerDay {
          display: none;
          position: relative;
          > span {
            position: absolute;
            left: 140px;
            top: 21px;
            overflow: hidden;
            > span {
              float: left;
              margin-right: 12px;
              &:first-child {
                margin-right: 14px;
              }
              > img {
                float: left;
                margin-right: 3px;

              }
            }
          }
          img {
            height: 240px;
          }
        }
      }
    }
  }
}
.offline_main_free {
  width: 287px;
  float: left;
  background-color: #f5f5f5;
  padding-bottom: 13px;
  .free_header > {
    .header_title {
      padding: 11px 0 20px;
      h3 {
        font-size: 19px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #d9d9d9;
        margin-left: 14px;
        margin-right: 15px;
        padding-bottom: 10px;
      }
    }
  }
  .free_body {
    clear: both;
    width: 298px;
    padding: 0px 0px 0px 15px;
    .free_body_con {
      overflow: hidden;
      margin-bottom: 11px;
      .label {
        text-align: center;
        width: 47px;
        height: 16px;
        float: left;
        font-size: 11px;
        color: #fff;
      }
      .label.closed {
        background-color: #5a5a5a;
      }
      .label.soon_closed {
        background-color: #d7004c;
      }
      .label.open {
        background-color: #0054a7;
      }
      .title {
        float: left;
        text-align: left;
        padding-left: 14px;
        width: 217px;
        overflow: hidden;
        a.title_desc {
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }
      }
    }
  }
  .free_body_btn {
    overflow: hidden;
    p.free_body_btn_schedule {
      background-color: #0054a7;
      margin-right: 9px;
    }
    p {
      float: left;
      text-align: center;
      width: 125px;
      height: 40px;
      line-height: 40px;
      background-color: #2f2f2f;
      a {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.offline_review {
  margin-bottom: 40px;
  position: relative;
}
.offline_review_title {
  position: relative;
  border-bottom: 2px solid #0054a7;
  padding-bottom: 10px;
  h3 {
    font-size: 22px;
    color: #0054a7;
    font-weight: bold;
  }
  .more_btn {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
.offline_review_rolling {
  position: relative;
  height:270px;
  overflow: hidden;
  width: 950px;
}
.review_rolling_arrow {
    position: absolute;
    top: 170px;
  }
  .prev {
    left: 0px;
  }
  .next {
    right: 0px;
  }
.review_rolling {
  padding-left: 60px;
  overflow: hidden;
  width: 890px;
  margin: 20px auto 0;
  position: absolute;
  top: 0px;
  //li:nth-child(1), li:nth-child(2), li:nth-child(3), li:nth-child(4), li:nth-child(5), li:nth-child(6) {
  //  display: block;
  //}
  li {
    margin-right: 10px;
    width: 168px;
    height: 250px;
    border: 1px solid #ededed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    float: left;
    .review_list_thumb {
      position: absolute;
      top: 80px;
      left: 40px;
      border: 1px solid #dedede;
      background: #dedede;
      width: 90px;
      height: 90px;
      overflow: hidden;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    .review_list_head {
      background: #ededed;
      text-align: center;
      height: 140px;
      .list_company_name {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        padding-top: 30px;
      }
    }
    .review_list_body {
      text-align: center;
      .list_teacher_name {
        color: #0054a7;
        font-weight: bold;
        font-size: 12px;
        padding-top: 41px;
      }
      .list_lecture {
        padding-top: 10px;
        span {
          font-weight: bold;
        }
      }
    }
  }
}
.pass_item.review_pass_item {
  height: 29px;
  border-bottom: 1px solid #ededed;
  overflow: hidden;
  >div {
    height: 29px;
    line-height: 30px;
    float: left;
    text-align: center;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .t_name {
    width: 122px !important;
  }
  .c_name {
    width: 218px !important;
  }
  .duty {
    width: 82px !important;
  }
  .pass_step {
    width: 162px !important;
  }
  .gender {
    width: 83px !important;
  }
  .age {
    width: 83px !important;
  }
  .school {
    width: 83px !important;
  }
  .grade {
    width: 83px !important;
  }
  .toeic {
    width: 83px !important;
  }
}

.offline_pass {
  span.offline_pass_span {
    font-size: 14px;
    font-weight: normal;
    color: #000;
  }
  thead.thead tr th {
    border-top: 2px solid #ededed;
    height: 30px;
  }
}

/* 마감 임박 강의 */
.closeLecture_wrap {
  margin-top: 20px;
  margin-bottom: 30px;
  .closeLecture_list {
    overflow: hidden;
    li:nth-child(2n) {
      margin-right: 0px;
    }
    li {
      float: left;
      width: 493px;
      height: 121px;
      border: 1px solid #ededed;
      margin-right: 9px;
      margin-bottom: 10px;
      .closeLecture_thumb {
        border-right: 1px solid #ededed;
        float: left;
        width: 122px;
        height: 122px;
        img {
          width: 122px;
          height: 122px;
        }
      }
      .closeLecture_con {
        float: left;
        width: 371px;
        .closeLecture_title {
          padding-left: 25px;
          height: 70px;
          padding-top: 20px;
          width: 325px;
          overflow: hidden;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
          word-wrap: normal;
          white-space: nowrap;
          p {
            color: #000;
            font-weight: bold;
            font-size: 14px;
          }
        }
        .closeLecture_detail {
          padding-left: 25px;
          background: #f5f5f5;
          padding-top: 10px;
          padding-bottom: 10px;
          .detail_con {
            overflow: hidden;
            p {
              float: left;
              color: #000;
              font-size: 12px;
              span.line {
                display: inline-block;
                width: 1px;
                height: 10px;
                background: #000;
                margin: 0 6px;
              }
              span {
                font-weight: bold;
                color: #000;
                font-size: 12px;
              }
            }
            p:first-child {
              width: 210px;
            }
          }
        }
      }
    }
  }
}

.teacher_container {
  position: relative;
  width: 1000px;
  height: 284px;
  overflow: hidden;
  margin-top: 20px;
  #teacher_slide {
    position: absolute;
    top: 0px;
    left: 0px;
    .item {
    margin-right: 2px;

    }
  }
  #teacher_larr {
    position: absolute;
    left: 20px;
    top: 127px;
    z-index: 3;
    cursor: pointer;
  }
  #teacher_rarr {
    position: absolute;
    right: 20px;
    top: 127px;
    z-index: 3;
    cursor: pointer;
  }
  #teacher_slide .item {
    float: left;
    width: 198px;
    height: 284px;
    img {
      width: 198px;
      height: 284px;
    }
  }
}
.teacher_container {
  .img_button {
    display: none;
  }
}
.teacher_container:hover {
  .img_button {
    display: block;
  }
}
#review_container {
  width: 390px;
  .poster {
    float: left;
    height: 162px !important;
    width: 151px !important;
    position: relative;
    text-align: center;
  }
  .desc {
    float: left;
    width: 239px;
    height: 162px;
    position: relative;
    overflow: hidden;
    letter-spacing: -1px;
    > {
      #rv_larr {
        position: absolute;
        left: 10px;
        top: 70px;
        z-index: 3;
        cursor: pointer;
      }
      #rv_rarr {
        position: absolute;
        right: 10px;
        top: 70px;
        z-index: 3;
        cursor: pointer;
      }
    }
  }
}

#rv_slide {
  position: absolute;
  height: 164px;
  > li {
    width: 241px;
    height: 162px;
    float: left;
    background: url('/static/images/offline/bg_review.gif') repeat-y;
    cursor: pointer;
    border: 1px solid transparent;
    &:hover {
      opacity: 0.5;
      filter: alpha(opacity = 50);
    }
    > .description {
      float: left;
      width: 230px;
      height: 134px;
      position: relative;
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 20px;
      .subject {
        font-weight: bold;
        font-size: 16px;
        height: 70px;
        text-align: left;
        vertical-align: top;
        width: 190px;
      }
      .teacher_name {
        color: #57a1d9;
        font-size: 12px;
        font-weight: bold;
        height: 30px;
        vertical-align: bottom;
      }
      .class_name {
        font-size: 12px;
        height: 30px;
        vertical-align: middle;
      }
    }
  }
}